@import '../../theme/animations';
@import '../../theme/border';
@import '../../theme/font-weight';
@import '../../theme/spacing';
@import '../../theme/state-colors';
@import './textfield.shared.module';

.wrapper {
  @include shared-wrapper;
}

.input-row {
  @include shared-input-row-base;

  &-success {
    @include shared-input-row-success;
  }

  &-error {
    @include shared-input-row-error;
  }

  &-disabled {
    @include shared-input-row-disabled;
  }
}

.input {
  @include shared-input-base;

  &[type='date'] {
    text-transform: uppercase;
  }

  // not done via ´rightSlot´ as the default icon in Firefox currently can't be hidden
  &::-webkit-calendar-picker-indicator {
    transition: opacity $small-intro;
    opacity: 1;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &:not([type='time'])::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none"><path fill="currentColor" fill-rule="evenodd" d="M5.65 1.5a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1ZM3 4.5a.5.5 0 0 0-.5.5v1.833h12V5a.5.5 0 0 0-.5-.5h-.25a1 1 0 1 1 0-2H14A2.5 2.5 0 0 1 16.5 5v8a2.5 2.5 0 0 1-2.5 2.5H3A2.5 2.5 0 0 1 .5 13V5A2.5 2.5 0 0 1 3 2.5h.133a1 1 0 1 1 0 2H3Zm11.5 4.334h-12V13a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V8.834ZM7.25 3.5a1 1 0 0 1 1-1h.5a1 1 0 0 1 0 2h-.5a1 1 0 0 1-1-1Zm3.95 2a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1Z" clip-rule="evenodd"/></svg>');
  }

  &-left-slot {
    @include shared-input-left-slot;
  }

  &-right-slot {
    @include shared-input-right-slot;
  }

  &-bold {
    font-weight: $font-weight-bold;
  }

  &-center {
    text-align: center;
  }
}

.slot {
  &-right {
    @include shared-slot;
  }

  &-left {
    @extend %slot;

    padding-left: $spacing-xs;
    border-top-left-radius: $border-radius-s;
    border-bottom-left-radius: $border-radius-s;

    &::before {
      @extend %slot-pseudo;

      mask-image: linear-gradient(270deg, rgb(0 0 0 / 10%), rgb(0 0 0 / 100%));
      right: calc($spacing-xs * -1);
    }
  }
}

.label-wrapper {
  @include shared-label-wrapper;

  .tooltip-trigger {
    display: grid;
  }
}

.info-icon {
  cursor: pointer;
}

.suffix-text {
  color: $color-gray;
}

.sub-label {
  color: $color-gray;
}
