@import '../../theme/spacing';
@import '../../theme/state-colors';
@import '../../theme/border';

%slot {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
}

%slot-pseudo {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  width: $spacing-xs;
  height: 100%;
  background-color: inherit;
  pointer-events: none;
}

@mixin shared-input-row-base {
  & {
    display: flex;
    height: 2.6875rem;
    border-width: $border-width-input;
    border-style: solid;
    border-radius: $border-radius-s;
    border-color: currentcolor;
  }

  &:focus-within {
    border-color: $state-color-focus;
  }
}

@mixin shared-input-row-success {
  border-color: $state-color-success;

  &:focus-within {
    border-color: $state-color-success;
  }
}

@mixin shared-input-row-error {
  border-color: $state-color-error;

  &:focus-within {
    border-color: $state-color-error;
  }
}

@mixin shared-input-row-disabled {
  border-color: transparent;
  background-color: $state-color-inactive-light;
}

@mixin shared-wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-3xs;
}

@mixin shared-input-base {
  flex: 1;
  width: 100%; // w/o this there somehow is an implicit minimum width
  min-width: 0;
  height: 100%;
  padding-right: $spacing-xs;
  padding-left: $spacing-xs;
  border: none;
  border-radius: $border-radius-xxs;
  outline: none !important;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  font-feature-settings:
    'zero' on,
    'ss01' on,
    'cv05' on;

  &:disabled {
    color: rgb(0 0 0 / 50%);
  }

  &::placeholder {
    opacity: 1;
    color: $color-gray;
  }
}

@mixin shared-slot {
  padding-right: $spacing-xs;
  border-top-right-radius: $border-radius-s;
  border-bottom-right-radius: $border-radius-s;

  @extend %slot;

  &::after {
    left: calc($spacing-xs * -1);
    mask-image: linear-gradient(90deg, rgb(0 0 0 / 10%), rgb(0 0 0 / 100%));

    @extend %slot-pseudo;
  }
}

@mixin shared-input-right-slot {
  padding-right: $spacing-3xs;
}

@mixin shared-input-left-slot {
  padding-left: $spacing-3xs;
}

@mixin shared-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
